<div class="container">
  <div class="splash">
    <img src="/assets/images/DeOudeMuragie.webp" alt="Main Splash Image">
  </div>
  <div class="about">
    <h1>ABOUT US</h1>
    <div class="content">
      <div class="details">
        <p>De Oude Muragie centre originated in 1994 with Milky spot that supplied fresh milk and juices directly from the farm.</p>
  
        <p>It was home to one of Pretoria’s first garden services Disa Tuine (1988) still on the go and a Ladies Boutique. With the extension of Braam Pretorius in 1996 it was developed into a small business centre, named after a ruin that was on the original farm and the owners rural and Western Cape backgrounds.</p>
  
        <p>With a growing need for business owners looking for affordable easy accessible space, De Oude Muragie was completed in 2001.</p>
  
        <p>De Oude Muragie with it’s quaint Cape Dutch buildings and pergolas covered by ornamental vines, has a charming country feel in the centre of the busling suburb of Magalieskruin.</p>
  
        <p>As Landscapers the owners kept all the the original trees on the property that providers shaded parking.It houses a huge indigenous fig tree with a span of 40m that covers 3 shops.M</p>
  
        <p>One of the oldest Jacarandas a stately old lady provides much needed shade in summer and a stunning purple show in October.</p>
  
        <p>It’s home to 11 professional service providers. This destination center provides a wide range of daily necessities such as Key and laundry services, a bath and funky haircut for the 4 paws and pamper for the visitors at the Beauty-, Hair Salon and Health shop. Get a fabulous steak at the butchery, best filtered natural water, or visit our Restaurant and mini Supermarket.</p>
  
        <p>De Oude Muragie an easy accessible centre is situated on the corner of Braam Pretorius and Veronica streets in Magalieskruin Pretoria.</p>
      </div>
      <div class="slider">
        <div class="carousel-container">
          <div class="row1">
            <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig1">
              <div ngxSlickItem *ngFor="let image of images" class="slide">
                <img src="{{ image.img }}" width="100%">
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="row2">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
              <div ngxSlickItem *ngFor="let image of images" class="slide">
                <img src="{{ image.img }}" width="100%">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
