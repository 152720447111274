import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-aight',
  templateUrl: './shop-aight.component.html',
  styleUrls: ['./shop-aight.component.css']
})
export class ShopAightComponent implements OnInit {

  images = [  
    { img: "../assets/images/heel1.webp" },  
    { img: "../assets/images/heel2.webp" },  
    { img: "../assets/images/heel3.webp" },  
    { img: "../assets/images/heel4.webp" },  
    { img: "../assets/images/heel5.webp" } 
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  
  
  constructor() { }

  ngOnInit(): void {
  }

}
