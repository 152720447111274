<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>SPECIALISED HAIR, SKIN AND LASER</h1></div>
  <div>
    <ul>
      <li>A Talented Hairstylist and Beauty Therapist took hands to create a one stop space for all your beauty needs.</li>
      <li>&nbsp;</li>
      <li>Passion for hair and beauty together with a love for satisfied customers combined to give a winning recipe and a successful business.</li>
      <li>&nbsp;</li>
      <li>Contact us and see for yourself.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Tue-Fri</li>
      <li>08:00-17:00</li>
      <li>Sat</li>
      <li>08:00-14:00</li>
      <li><h3>Contact</h3></li>
      <li>Beauty Therapist:</li>
      <li>Shantelle</li>
      <li>082 568 2396</li>
      <li>&nbsp;</li>
      <li>Hair</li>
      <li>Lelani</li>
      <li>071 147 7936</li>
      <li>&nbsp;</li>
      <li>Mel</li>
      <li>071 483 1579</li>
      <li>&nbsp;</li>
      <li>Jackie</li>
      <li>082 571 2808</li>
      <li>&nbsp;</li>
      <li>Charlene</li>
      <li>082 335 0751</li>
    </ul>
  </div>
</div>