<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>THE HEEL AND KEY</h1></div>
  <div>
    <ul>
      <li>Come and experience one stop convenience at your door step.</li>
      <li>LOCKSMITH SERVICE</li>
      <li>KEY CUTTING</li>
      <li>A LARGE VARIETY OF SECURITY LOCKS</li>
      <li>We also stock a wide variety of shell and flip car keys.</li>
      <li>Chipping & coding of remote control keys.</li>
      <li>SHOE REPAIR EXPERTLY DONE HERE</li>
      <li>Reheeling and resoling of shoes. Stretching, stitching etc.</li>
      <li>REMOTES AND BATTERIES</li>
      <li>(Fitment of watch and remote batteries)</li>
      <li>STOCKING SHOE CARE PRODUCTS</li>
      <li>LAUNDRY AND DRY CLEAN SERVICE AND</li>
      <li>CLOTHING ALTERATIONS</li>
      <li>WASH DRY AND IRON</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mon-Fri</li>
      <li>07:00-17:00</li>
      <li>Sat</li>
      <li>08:00-13:00</li>
      <li>Public Holidays:</li>
      <li>Contact for hours</li>
      <li><h3>Contact</h3></li>
      <li>074 022 7686</li>
      <li>072 241 2932</li>
    </ul>
  </div>
</div>