<div class="container">
  <h1>SHOPS</h1>

  <div class="shops">
    <div class="shop">
      <figure class="effect">
        <a routerLink="/the-health-shop">
          <img src="https://static.wixstatic.com/media/371866_a77e6cc2dd2947798c0bdec8ed500302~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_a77e6cc2dd2947798c0bdec8ed500302~mv2.webp" alt="The Health Shop">
          <figcaption><h2>The Health Shop</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/stoep-stories">
          <img src="https://static.wixstatic.com/media/371866_5e420d9f822744a5a599649ba540812c~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_5e420d9f822744a5a599649ba540812c~mv2.webp" alt="Stoep Stories">
          <figcaption><h2>Stoep Stories</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/heel-and-key">
          <img src="https://static.wixstatic.com/media/371866_c699b21664b843b99f5ca91654f70394~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_c699b21664b843b99f5ca91654f70394~mv2.webp" alt="The Heel & Key">
          <figcaption><h2>The Heel & Key</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/bean-juice">
          <img src="https://static.wixstatic.com/media/371866_e307b1424edd4a6193c54ff2b29f811e~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_e307b1424edd4a6193c54ff2b29f811e~mv2.webp" alt="Bean Juice">
          <figcaption><h2>Bean Juice</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/watties-pampered-pooches">
          <img src="https://static.wixstatic.com/media/371866_15f877d225204bd5b012a28f7bda774f~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_15f877d225204bd5b012a28f7bda774f~mv2.webp" alt="Pampered Pooches">
          <figcaption><h2>Pampered Pooches</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/spesialized-hair-skin-and-laser">
          <img src="https://static.wixstatic.com/media/371866_cae36587892041e18e264f7719ba1c78~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_cae36587892041e18e264f7719ba1c78~mv2.webp" alt="Specialed Hair, Skin & Laser">
          <figcaption><h2>Specialized Hair, Skin & Laser</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/die-water-gat">
          <img src="https://static.wixstatic.com/media/371866_7d65adfb4330449a8d35813c9f5790ee~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_7d65adfb4330449a8d35813c9f5790ee~mv2.webp" alt="Die Water Gat">
          <figcaption><h2>Die Water Gat</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/maras-slaghuis">
          <img src="https://static.wixstatic.com/media/371866_cbec7e80f7484c6a9f91620ad6a58b58~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_cbec7e80f7484c6a9f91620ad6a58b58~mv2.webp" alt="Mara's Slaghuis">
          <figcaption><h2>Mara's Slaghuis</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a routerLink="/al-madina-supermarket">
          <img src="https://static.wixstatic.com/media/371866_63f59c3adf634ecdaffb8f2177d1f17a~mv2.jpg/v1/fill/w_298,h_285,al_c,q_80,usm_0.66_1.00_0.01/371866_63f59c3adf634ecdaffb8f2177d1f17a~mv2.webp" alt="Al-Madina">
          <figcaption><h2>Al-Madina</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    <div class="shop">
      <figure class="effect">
        <a href="/contact">
          <img src="https://static.wixstatic.com/media/371866_16b1a430a4bd4f9399e66de8c212f057~mv2_d_1426_1454_s_2.png/v1/fill/w_298,h_285,al_c,q_85,usm_0.66_1.00_0.01/371866_16b1a430a4bd4f9399e66de8c212f057~mv2_d_1426_1454_s_2.webp" alt="Enquire About Space">
          <figcaption><h2>Enquire About Space</h2></figcaption>
          <div class="cover"></div>
        </a>
      </figure>
    </div>
    
  </div>
</div>
