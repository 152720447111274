import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-one',
  templateUrl: './shop-one.component.html',
  styleUrls: ['./shop-one.component.css']
})
export class ShopOneComponent implements OnInit {

  images = [  
    { img: "../assets/images/health1.webp" },  
    { img: "../assets/images/health2.webp" },  
    { img: "../assets/images/health3.webp" },  
    { img: "../assets/images/health4.webp" },  
    { img: "../assets/images/health5.webp" } 
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  

  constructor() { }

  ngOnInit(): void {
  }

}
