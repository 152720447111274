<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>AL-MADINA SUPER-MARKET</h1></div>
  <div>
    <ul>
      <li>Wholesale and retail tobacco & cool drink supplier and general mini Supermarket.</li>
      <li>&nbsp;</li>
      <li>Come and visit this One Stop Convenience Shop to find everything you might need and more.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mon-Sun</li>
      <li>07:00- 20:00</li>
      <li><h3>Contact</h3></li>
      <li>084 015 9843</li>
    </ul>
  </div>
</div>