import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-nine',
  templateUrl: './shop-nine.component.html',
  styleUrls: ['./shop-nine.component.css']
})
export class ShopNineComponent implements OnInit {

  images = [  
    { img: "../assets/images/madina1.webp" },  
    { img: "../assets/images/madina2.webp" }
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  
  
  constructor() { }

  ngOnInit(): void {
  }

}
