<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>WATTIES PAMPERED POOCHES</h1></div>
  <div>
    <ul>
      <li>Treat your best friend to a wash, specialist shave-style, dip, nail care and many more. From young to old, cats or dogs, they are in good hands.</li>
      <li>&nbsp;</li>
      <li>Pampered Pooches has a pick up and delivery service, so no need for hair & nail marks on that beautiful seat of yours.</li>
      <li>&nbsp;</li>
      <li>Contact them and come home to your clean and pampered best friend.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mo-Fri</li>
      <li>08:00-15:00</li>
      <li>Sat</li>
      <li>08:30-11:00</li>
      <li><h3>Contact</h3></li>
      <li>078 081 2681</li>
      <li><a href="www.pamperedpooches.co.za">www.pamperedpooches.co.za</a></li>
    </ul>
  </div>
</div>