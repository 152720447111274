<nav id="nav-bar">
  <div class="logo">
    <span>
      <a routerLink="/"><h1>{{ title }}</h1></a>
    </span>
  </div>
  <div class="links">
    <a routerLink="/home" routerLinkActive="active"><h2>Home</h2></a>
    <div class="dropDown">
      <a routerLink="/shops" routerLinkActive="active"><h2>Shops</h2></a>
      <div class="dropDownList">
        <a routerLink="/the-health-shop" routerLinkActive="active"><h3>The Health Shop</h3></a>
        <a routerLink="/stoep-stories" routerLinkActive="active"><h3>Stoep Stories</h3></a>
        <a routerLink="/die-water-gat" routerLinkActive="active"><h3>Die Water Gat</h3></a>
        <a routerLink="/maras-slaghuis" routerLinkActive="active"><h3>Mara's Slaghuis & Bakkery</h3></a>
        <a routerLink="/spesialized-hair-skin-and-laser" routerLinkActive="active"><h3>Spesialized Hair, Skin and Laser</h3></a>
        <a routerLink="/bean-juice" routerLinkActive="active"><h3>Bean Juice</h3></a>
        <a routerLink="/watties-pampered-pooches" routerLinkActive="active"><h3>Watties Pampered Pooched</h3></a>
        <a routerLink="/heel-and-key" routerLinkActive="active"><h3>The Heel and Key</h3></a>
        <a routerLink="/al-madina-supermarket" routerLinkActive="active"><h3>Al-Madina Super-Market</h3></a>
      </div>
    </div>
    <a routerLink="/contact" routerLinkActive="active"><h2>Contact</h2></a>
  </div>
</nav>

<router-outlet></router-outlet>

<footer>
  <div>
    <h2>Corner of Braam Pretorius & Veronica street</h2>
    <h2>Magalieskruin</h2>
    <h2>Pretoria, South Africa</h2>
  </div>
</footer>
