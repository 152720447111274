import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-two',
  templateUrl: './shop-two.component.html',
  styleUrls: ['./shop-two.component.css']
})
export class ShopTwoComponent implements OnInit {

  images = [  
    { img: "../assets/images/stoep1.webp" },  
    { img: "../assets/images/stoep2.webp" },  
    { img: "../assets/images/stoep3.webp" },  
    { img: "../assets/images/stoep4.webp" },  
    { img: "../assets/images/stoep5.webp" } 
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  

  constructor() { }

  ngOnInit(): void {
  }

}
