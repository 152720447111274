<div class="container">
  <h1>CONTACT</h1>
  <h1>US</h1>
  <p><span>The Oude Muragie MANAGEMENT:</span></p>
  <p><span>Johan:</span> 083 263 4473</p>
  <p><span>Karin:</span>083 444 4750 (admin/retals)</p>
  <p>&nbsp;</p>
  <p>EMAIL:</p>
  <p><a href="mailto:info@deoudemuragie.co.za">info@deoudemuragie.co.za</a></p>
</div>
