import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-three',
  templateUrl: './shop-three.component.html',
  styleUrls: ['./shop-three.component.css']
})
export class ShopThreeComponent implements OnInit {

  images = [  
    { img: "../assets/images/water1.webp" },  
    { img: "../assets/images/water2.webp" },  
    { img: "../assets/images/water3.webp" }
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  

  constructor() { }

  ngOnInit(): void {
  }

}
