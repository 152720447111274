<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>DIE WATER GAT</h1></div>
  <div>
    <ul>
      <li>Meet <a href="https://www.facebook.com/bydiewatergat/?fref=mentions">Die Water Gat</a></li>
      <li>&nbsp;</li>
      <li>For the best water in Pretoria. Customize bottles and lids to suit any occasion, no order is too large or too small.</li>
      <li>&nbsp;</li>
      <li>For ice cream, water, ice, good service & good prices.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mo-Fri</li>
      <li>07:30-18:30</li>
      <li>Sat</li>
      <li>08:00-16:00</li>
      <li>Sun/Public</li>
      <li>Holidays</li>
      <li>Public Holidays:</li>
      <li>09:00-14:00</li>
      <li><h3>Contact</h3></li>
      <li>076 636 0186</li>
      <li><a href="www.diewatergat.co.za">www.diewatergat.co.za</a></li>
    </ul>
  </div>
</div>
