<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>STOEP STORIES</h1></div>
  <div>
    <ul>
      <li>Meet <a href="https://www.facebook.com/Stoep-Stories-Restaurant-Pub-and-Grill-105286507616320/">Stoep Stories Restaurant Pub and Grill</a></li>
      <li>&nbsp;</li>
      <li>Great family restaurant, live entertainment with excellent service and delicious meals.</li>
      <li>&nbsp;</li>
      <li>On tap Craft beer, FREE WiFi & more available.</li>
      <li>&nbsp;</li>
      <li>Contact us for takeaways, reservations & upcoming live entertainment.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Tue-Thu</li>
      <li>09:00-21:00</li>
      <li>Fri-Sat</li>
      <li>09:00-22:00</li>
      <li>Sun</li>
      <li>Closed</li>
      <li>Public Holidays:</li>
      <li>contact for hours</li>
      <li><h3>Contact</h3></li>
      <li>Frans van Zyl</li>
      <li>(owner)</li>
      <li>073 257 9628</li>
      <li><a href="mailto:manager@dedekke.net">manager@dedekke.net</a></li>
    </ul>
  </div>
</div>