<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>MARA'S SLAGHUIS</h1></div>
  <div>
    <ul>
      <li>Freshly baked bread accompanied by amazing meat at the best prices is never a bad idea.</li>
      <li>&nbsp;</li>
      <li>Come and have a look at what "Mara's Slaghuis & Bakkery" has to offer you.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mon-Fri</li>
      <li>08:30-17:30</li>
      <li>Sat</li>
      <li>07:30-14:30</li>
      <li>Sun</li>
      <li>Closed</li>
      <li><h3>Contact</h3></li>
      <li>072 124 4947</li>
    </ul>
  </div>
</div>
