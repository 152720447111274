import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-six',
  templateUrl: './shop-six.component.html',
  styleUrls: ['./shop-six.component.css']
})
export class ShopSixComponent implements OnInit {

  images = [  
    { img: "../assets/images/bean1.webp" },  
    { img: "../assets/images/bean2.webp" },  
    { img: "../assets/images/bean3.webp" },  
    { img: "../assets/images/bean4.webp" },  
    { img: "../assets/images/bean5.webp" },
    { img: "../assets/images/bean6.webp" }
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  
  
  constructor() { }

  ngOnInit(): void {
  }

}
