import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-four',
  templateUrl: './shop-four.component.html',
  styleUrls: ['./shop-four.component.css']
})
export class ShopFourComponent implements OnInit {

  images = [  
    { img: "../assets/images/maras1.webp" },  
    { img: "../assets/images/maras2.webp" },  
    { img: "../assets/images/maras3.webp" }
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  

  constructor() { }

  ngOnInit(): void {
  }

}
