<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>THE HEALTH SHOP</h1></div>
  <div>
    <ul>
      <li>* Professional Dietary Consultations.</li>
      <li>* Geneway genetic tests with expert feedback.</li>
      <li>* Variety of 10-day food packages (low GI, wheat & gluten-free packages, low carb, Metabolic Detox)</li>
      <li>* Various health foods, snacks and drinks.</li>
      <li>* Living probiotic foods and drinks.</li>
      <li>* Therapeutic water with a pH of 9 to 10.</li>
      <li>* Supplements like Solgar and Xymogen.</li>
      <li>* We do not just work out diets, we change peoples perception about nutrition! We wish to convey the concept of healthy, functional nutrition.</li>
      <li>* Our mission is to make people feel healthier, become more energetic and get them excited and feeling light and happy about healthy food.</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mon-Thu </li>
      <li>09:00-17:00</li>
      <li>Fri</li>
      <li>09:00-15:30</li>
      <li>Sat</li>
      <li>09:00-13:00</li>
      <li><h3>Contact</h3></li>
      <li>012 548 2762</li>
      <li>083 251 2904</li>
      <li><a href="https://healthlink.co.za">www.healthlink.co.za</a></li>
    </ul>
  </div>
</div>