import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopOneComponent } from './shop-one/shop-one.component';
import { ShopTwoComponent } from './shop-two/shop-two.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { ShopListComponent } from './shop-list/shop-list.component';
import { ShopThreeComponent } from './shop-three/shop-three.component';
import { ShopFourComponent } from './shop-four/shop-four.component';
import { ShopFiveComponent } from './shop-five/shop-five.component';
import { ShopSixComponent } from './shop-six/shop-six.component';
import { ShopSevenComponent } from './shop-seven/shop-seven.component';
import { ShopAightComponent } from './shop-aight/shop-aight.component';
import { ShopNineComponent } from './shop-nine/shop-nine.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'shops', component: ShopListComponent},
  {path: 'the-health-shop', component: ShopOneComponent},
  {path: 'stoep-stories', component: ShopTwoComponent},
  {path: 'die-water-gat', component: ShopThreeComponent},
  {path: 'maras-slaghuis', component: ShopFourComponent},
  {path: 'spesialized-hair-skin-and-laser', component: ShopFiveComponent},
  {path: 'bean-juice', component: ShopSixComponent},
  {path: 'watties-pampered-pooches', component: ShopSevenComponent},
  {path: 'heel-and-key', component: ShopAightComponent},
  {path: 'al-madina-supermarket', component: ShopNineComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [HomeComponent, ShopListComponent, ShopOneComponent, ShopTwoComponent, ShopThreeComponent, ShopFourComponent, ShopFiveComponent, ShopSixComponent, ShopSevenComponent, ShopAightComponent, ShopNineComponent, ContactUsComponent, PageNotFoundComponent];
