import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-five',
  templateUrl: './shop-five.component.html',
  styleUrls: ['./shop-five.component.css']
})
export class ShopFiveComponent implements OnInit {

  images = [  
    { img: "../assets/images/laser1.webp" },  
    { img: "../assets/images/laser2.webp" },  
    { img: "../assets/images/laser3.webp" },  
    { img: "../assets/images/laser4.webp" },  
    { img: "../assets/images/laser5.webp" },
    { img: "../assets/images/laser6.webp" }
  ];

  slideConfig = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false
  };  
  
  constructor() { }

  ngOnInit(): void {
  }

}
