import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  images = [  
    { img: "../assets/images/home1.jpg" },  
    { img: "../assets/images/home2.jpg" },  
    { img: "../assets/images/home3.jpg" },  
    { img: "../assets/images/home4.jpg" },  
    { img: "../assets/images/home5.jpg" } 
  ];

  slideConfig1 = {  
    "slidesToShow": 1,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "fade": true,
    "arrows": false,
    "asNavFor": '.carousel',
    "pauseOnFocus": false
  };  

  slideConfig2 = {  
    "slidesToShow": 3,  
    "slidesToScroll": 1, 
    "infinite": true,
    "autoplay": true,
    "draggable": false,
    "arrows": false,
    "asNavFor": '.slider-for',
    "focusOnSelect": true
  };  

  constructor() { }

  ngOnInit(): void {
  } 
    
}
