<div class="container">
  <div class="carousel-container">
    <div class="row1">
      <ngx-slick-carousel class="slider-for" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let image of images" class="slide">
          <img src="{{ image.img }}" width="100%">
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>

<div class="details">
  <div><h1>BEAN JUICE <span>coffee bar</span></h1></div>
  <div>
    <ul>
      <li>For a fresh approach to your weekly cup of coffee visit bean juice coffee bar.</li>
      <li>&nbsp;</li>
      <li>Come and enjoy fresh muffins, scones, quiches, cakes etc. & of course top quality coffee.</li>
      <li>&nbsp;</li>
      <li>Take a break from the business of life.</li>
      <li>&nbsp;</li>
      <li>Its the moment you've "BEAN" waiting for...</li>
    </ul>
  </div>
  <div>
    <ul>
      <li><h3>HOURS</h3></li>
      <li>Mon-Fri</li>
      <li>06:30-18:00</li>
      <li>Sat</li>
      <li>09:00-13:00</li>
      <li>Sun</li>
      <li>Closed</li>
      <li>Public Holidays:</li>
      <li>Contact for hours</li>
      <li><h3>Contact</h3></li>
      <li>073 356 0458</li>
      <li>083 665 6308</li>
      <li>&nbsp;</li>
      <li><a href="mailto:info@beanjuice">info@beanjuice</a></li>
      <li><a href="coffee.co.za">coffee.co.za</a></li>
    </ul>
  </div>
</div>